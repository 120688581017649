/**
 * Facebook login JavaScript.
 *
 * I've re-written so it works on both Webpack and non-Webpack sites, made it a bit more flexible and removed old stuff.
 *
 * If you're changing it, make sure you check:
 * - Each website's login page (/login)
 * - Each website's sign up page (/signup)
 * - Competition sign up page (two different types of comp, ask Dan how to access)
 * - Clickthrough login page (any clickthrough URL with 'require_login=true' parameter appended WHILST LOGGED OUT)
 *
 * Usage:
 * - Make sure the page contains a '<meta property="fb:app_id">' element with the Facebook app ID in.
 * - Add .js-login-facebook to a button.
 * - Add any options below if needed.
 *
 * Options (using data attributes):
 * - data-destination, override the default destination (/dashboard) with a custom one. Useful for clickthrough/comp pages which don't take the user to the dashboard afterwards.
 * - data-new-signup-destination, override the default destination (/signup/install) if the login is a new sign up.
 *
 * Options (JavaScript):
 * - onAuthSuccess, callback that's called when a user has given our app access and logged in / signed up successfully. Overriding this will replace the default behaviour (which is a redirect)
 *
 * @author Daniel Moffat
 *
 * todo: When GAYL is Webpack'ified, remove the conditionals at the top, replace HTML string with template literal
 * and remove the conditional init stuff.
 */

const buildIcon = require("Scripts/common/build-icon");
const createModal = require("Scripts/common/create-modal");
const AnalyticsCheck = require('Scripts/common/analytics-check');
const LoadingButton = require('Scripts/common/loading-button');

module.exports = FacebookLogin;

function FacebookLogin(options) {
	var self = this;

	this.appId = parseInt($('meta[property="fb:app_id"]').attr('content'));
	this.facebookSdkHasLoaded = false;
	this.facebookAccessToken = null;

	this.init = function() {
		options = options || {};
		this.button = new LoadingButton($('.js-login-facebook'));
		this.destination = this.button.el().data('destination') || null;
		this.onAuthSuccess = options.onAuthSuccess || this.defaultOnAuthSuccess.bind(this);

		// GAYL compatibility - don't initialise if the button isn't on the page.
		if(!this.button.el().length) {
			console.log('FacebookLogin.init()', 'Button not found, not initialising');
			return;
		}

		if(!this.appId) {
			throw new Error('No app ID found. Make sure this element exists on the page: <meta property="fb:app_id" content="APP_ID">');
		}

		this.bindEventHandlers();

		if(AnalyticsCheck("social-media")) {
			this.loadFacebookSdk();
		}
		console.log('FacebookLogin.init()', 'Button initialised');
	};

	this.bindEventHandlers = function() {
		this.button.el().on('click', this.onClickButton.bind(this));
	};

	this.onClickButton = function(e) {
		e.preventDefault();

		console.log("FacebookLogin.onClickButton", "Facebook App ID", this.appId);
		
		this.button.disable();
		
		this.loadFacebookSdk(function() {
			console.log("FacebookLogin.onClickButton");

			if(self.facebookAccessToken == null) {
				FB.login(function(resp) {
					console.log("FacebookLogin.onClickButton", "FB.login Response", resp.authResponse);

					if(resp.authResponse) {
						self.postAuthorise(resp.authResponse.accessToken);
					} 
					else {
						self.button.enable();
					}
				}, {scope: 'email'});
			}
			else {
				self.postAuthorise(self.facebookAccessToken);
			}
		});
	};

	// Called when the user authorises - can be overidden by callers
	this.defaultOnAuthSuccess = function(redirectUrl) {
		console.log('onAuthSuccess: ' + redirectUrl);
		window.location.href = redirectUrl;
	};

	// Called after the user has logged into Facebook and connected with our app.
	this.postAuthorise = function(accessToken) {
		console.log("FacebookLogin.postAuthorise()");

		this.checkHasProvidedEmail(function(hasProvidedEmail) {
			console.log("FacebookLogin.postAuthorise()", "Has user provided their email?", hasProvidedEmail);
			if(hasProvidedEmail) {
				self.login(accessToken);
			} else {
				self.promptForEmail();
			}
		});
	};

	// Checks if the user has provided their email address when authorising our app. Callback is called with whether they have or not.
	this.checkHasProvidedEmail = function(callback) {
		FB.api('/me/permissions', function(resp) {
			var permissions = resp.data

			if(!permissions) { 
				callback(false);
				return;
			}

			for (var i = 0; i < permissions.length; i++) {
				if(permissions[i].permission === 'email') {
					callback(permissions[i].status !== 'declined');
				}
			}
		});
	};

	// Displays a modal to the user asking them for email permissions.
	this.promptForEmail = function() {
		// Ensure the modal is only added once to the document
		if($('#js-fb-modal').length === 0) {
			createModal({
				id: "js-fb-modal",
				heading: 'Email address required',
				content:
					'<p>You must share your email address with us in order to use our services.</p><p>We use your email address to uniquely identify your account and send you confirmation of funds raised.</p>' +
					'<button id="js-fb-continue" type="button" data-bs-dismiss="modal" class="btn btn-green btn-lg btn-block" id="continue">Continue ' + buildIcon("fa-angle-right") + '</button>' +
					'<button type="button" data-bs-dismiss="modal" class="btn btn-silver btn-block">' + buildIcon("fa-xmark") + ' Cancel</button>'
			});

			$('#js-fb-continue').on('click', function (e) {
				console.log("FacebookLogin.promptForEmail", "Modal button click");

				FB.login(function(resp) {
					if(!resp.authResponse.grantedScopes || resp.authResponse.grantedScopes.indexOf('email') === -1) {
						self.promptForEmail();
					}
					else if(resp.authResponse) {
						self.postAuthorise(resp.authResponse.accessToken);
					}
				}, {scope: 'email', auth_type: 'rerequest', return_scopes: true});
			});
		}

		$('#js-fb-modal').modal('show');
		self.button.enable();
	};

	// Calls our backend with the given accessToken. Only ever called when the user has authorised our app
	// and provided their email address.
	this.login = function(accessToken) {
		$.ajax({
			url: '/facebook',
			method: 'POST',
			data: self.getFormData(accessToken)
		}).then(function(resp) {
			self.button.enable();
			if(resp.error) { 
				// User didn't provide their email address - apparently to do with Facebook users who log in with a mobile number
				if(resp.error === 'EmailMissing') {
					FB.logout(function(resp) {
						self.promptForEmail();
					})
				} else if(resp.error === 'ClosedAccount') {
					createModal({
						id: "js-fb-closed-account-modal",
						heading: 'Your account is closed',
						content:
							'<p>Sorry, you have previously requested to close your Give as you Live account. Please contact us to re-open it.</p>' +
							'<a href="/contact-us" class="btn btn-green btn-lg btn-block">Contact us ' + buildIcon("fa-angle-right") + '</a>' +
							'<button type="button" data-bs-dismiss="modal" class="btn btn-silver btn-block">' + buildIcon("fa-xmark") + ' Cancel</button>'
					});

					$('#js-fb-closed-account-modal').modal('show');
				} else { // Backend failed to read Graph API.
					// Not very helpful, taken from the old code.
					alert('Please try again later');
				}
				return;
			}

			self.onAuthSuccess(resp.dest);
		});
	};

	this.getFormData = function(accessToken) {
		var data = {
			facebookAccessToken: accessToken,
			rurl: $('#rurl').val() || self.destination || null
		};

		console.log("getFormData", data);

		return data;
	}

	// Loads the SDK and calls the given callback afterwards. The SDK will only be loaded once, but the callback will always be executed.
	this.loadFacebookSdk = function(callback) {
		if(this.facebookSdkHasLoaded) {
			if(callback) {
				callback();
			}
			return;
		}

		// Callback invoked when SDK has loaded
		window.fbAsyncInit = function() {
			this.facebookSdkHasLoaded = true;
			FB.init({
				appId: this.appId,
				xfbml: false,
				autoLogAppEvents: false,
				version: 'v3.3'
			});

			FB.getLoginStatus(function(resp) {
				console.log("FacebookLogin.loadFacebookSdk", "FB.getLoginStatus Response", resp.authResponse);
				self.facebookAccessToken = resp.status === 'connected' ? resp.authResponse.accessToken : null;
			});

			// Call our custom callback when initailised
			if(callback) {
				callback();
			};
		}.bind(this);

		// Loads the SDK
		(function(d, s, id){
			var js, fjs = d.getElementsByTagName(s)[0];
			if(d.getElementById(id)) {return;}
			js = d.createElement(s);
			js.id = id;
			js.src = "//connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	};

	this.init();
}