/**
 * Simple wrapper function for creating a Bootstrap modal
 * Opts:
 * - heading (just text, close button is automatically added)
 * - body (HTML)
 */

const buildIcon = require("Scripts/common/build-icon");

module.exports = createModal;

function createModal(opts) {
	const closeButton = '<button type="button" class="modal-close-icon" data-bs-dismiss="modal" aria-label="Close">' + buildIcon("fa-xmark", {circle:true, classes:'text-grey', attributes:{transform:'shrink-6'}}) + '</button>';

	const modalHeader = opts.heading ? '<div class="modal-header"><h5 class="modal-title">' + opts.heading + '</h5>' + closeButton + '</div>' : closeButton;

	const modalHtml = '<div id="' + (opts.id || '') + '" class="modal fade">' + 
		'<div class="modal-dialog">' +
			'<div class="modal-dialog">' +
				'<div class="modal-content">' +
					modalHeader + 
					'<div class="modal-body">' + opts.content + '</div>' +
				'</div>' +
			'</div>' +
		'</div>' + 
	'</div>';

	$('body').append(modalHtml);
}